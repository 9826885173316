import React from 'react'

export const Tampon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="152" height="152" viewBox="0 0 152 152" fill="none" className={className}>
            <mask id="path-1-inside-1_144_1796" fill="white">
                <path d="M126.011 83.3701L126.492 77.4413L137.413 78.3279L137.308 79.6228L126.543 78.7489L126.167 83.3827L126.011 83.3701Z"/>
                <path d="M124.889 87.6902C125.176 86.6326 125.711 85.8533 126.496 85.3523C127.28 84.8513 128.302 84.7714 129.561 85.1126C130.82 85.4538 131.704 86.061 132.214 86.9343C132.72 87.8176 132.83 88.7881 132.544 89.8457C132.255 90.9133 131.718 91.6977 130.933 92.1987C130.149 92.6997 129.127 92.7796 127.868 92.4384C126.609 92.0972 125.726 91.4849 125.219 90.6016C124.71 89.7283 124.6 88.7579 124.889 87.6902ZM132.415 89.8431C132.622 89.0776 132.452 88.3503 131.903 87.661C131.365 86.9746 130.486 86.4662 129.268 86.1359C128.059 85.8084 127.1 85.7971 126.39 86.1021C125.68 86.4071 125.222 86.9424 125.014 87.7079C124.807 88.4734 124.972 89.1994 125.511 89.8858C126.059 90.5751 126.938 91.0834 128.146 91.411C129.365 91.7413 130.329 91.7539 131.039 91.4489C131.749 91.1439 132.207 90.6086 132.415 89.8431Z"/>
                <path d="M126.923 103.736L126.966 103.861C126.929 103.89 126.85 103.91 126.729 103.921C126.604 103.942 126.501 103.938 126.421 103.912C126.337 103.894 126.262 103.87 126.196 103.838C125.942 103.716 125.711 103.535 125.502 103.295L121.163 97.872L121.231 97.731L129.546 98.5016L129.071 99.489L122.193 98.8366L125.57 103.154C125.713 103.362 125.921 103.532 126.193 103.663C126.466 103.794 126.709 103.819 126.923 103.736Z"/>
                <path d="M121.028 105.678C119.973 104.928 119.069 104.561 118.316 104.576C117.556 104.6 116.962 104.913 116.533 105.517C115.928 106.368 115.518 107.216 115.301 108.06L115.185 108.035C115.405 107.167 115.82 106.304 116.431 105.445C117.035 104.594 117.783 104.082 118.674 103.909C119.566 103.736 120.543 104.027 121.606 104.783C122.669 105.539 123.295 106.406 123.483 107.385C123.671 108.363 123.456 109.286 122.84 110.154C122.344 110.851 121.718 111.29 120.963 111.47C120.208 111.65 119.422 111.45 118.605 110.87C118.563 110.839 118.52 110.809 118.477 110.779L121.715 106.224C121.503 106.035 121.274 105.853 121.028 105.678ZM119.196 109.984C120.019 110.556 120.735 110.828 121.343 110.8C121.952 110.771 122.429 110.515 122.774 110.03C123.118 109.545 123.214 108.96 123.061 108.275C122.902 107.599 122.484 106.943 121.808 106.309L119.196 109.984Z"/>
                <path d="M104.041 117.546L108.693 113.84L115.521 122.41L114.504 123.22L107.775 114.772L104.138 117.668L104.041 117.546Z"/>
                <path d="M100.122 119.668C101.089 119.153 102.024 119.01 102.926 119.239C103.828 119.468 104.586 120.158 105.199 121.309C105.812 122.461 105.975 123.521 105.687 124.49C105.39 125.464 104.758 126.208 103.79 126.723C102.814 127.243 101.875 127.389 100.973 127.16C100.07 126.931 99.3127 126.241 98.6996 125.089C98.0865 123.938 97.9285 122.875 98.2256 121.901C98.5135 120.932 99.1456 120.188 100.122 119.668ZM103.704 126.628C104.404 126.255 104.818 125.633 104.945 124.761C105.076 123.899 104.846 122.91 104.252 121.796C103.664 120.69 103.015 119.983 102.307 119.675C101.599 119.366 100.895 119.398 100.195 119.771C99.4946 120.144 99.0786 120.761 98.9467 121.624C98.8197 122.495 99.0505 123.484 99.6391 124.589C100.233 125.704 100.883 126.415 101.592 126.724C102.3 127.032 103.004 127 103.704 126.628Z"/>
                <path d="M89.8186 132.126L89.7574 132.243C89.7113 132.236 89.6426 132.192 89.5515 132.112C89.4504 132.034 89.3822 131.957 89.3471 131.881C89.3021 131.807 89.2685 131.736 89.2465 131.666C89.1615 131.398 89.1347 131.105 89.166 130.789L90.1438 123.913L90.293 123.865L95.4288 130.45L94.3841 130.78L90.1467 125.324L89.3152 130.742C89.2619 130.988 89.2809 131.256 89.3721 131.544C89.4634 131.833 89.6122 132.027 89.8186 132.126Z"/>
                <path d="M84.3853 129.142C84.2113 127.86 83.8613 126.949 83.3353 126.41C82.7989 125.872 82.1637 125.652 81.4295 125.752C80.3954 125.892 79.4961 126.172 78.7316 126.592L78.6703 126.49C79.4541 126.057 80.3682 125.77 81.4126 125.628C82.4467 125.488 83.3316 125.684 84.0672 126.216C84.8029 126.748 85.2584 127.66 85.4337 128.953C85.6091 130.245 85.4039 131.295 84.8182 132.101C84.2325 132.907 83.4123 133.381 82.3575 133.525C81.5096 133.64 80.7617 133.483 80.1139 133.055C79.466 132.627 79.0748 131.916 78.9401 130.924C78.9331 130.872 78.9261 130.82 78.9191 130.768L84.4566 130.017C84.4497 129.734 84.426 129.442 84.3853 129.142ZM79.9907 130.749C80.1357 131.741 80.4266 132.449 80.8634 132.874C81.3002 133.299 81.8133 133.472 82.4027 133.392C82.9922 133.312 83.4845 132.982 83.8797 132.402C84.2645 131.823 84.4573 131.07 84.4579 130.143L79.9907 130.749Z"/>
                <path d="M63.9276 124.833L69.8123 125.701L68.2144 136.541L66.9291 136.351L68.5041 125.666L63.9048 124.988L63.9276 124.833Z"/>
                <path d="M59.7384 123.41C60.774 123.768 61.5151 124.355 61.9616 125.172C62.4082 125.989 62.4184 127.013 61.9925 128.246C61.5665 129.479 60.9006 130.32 59.9947 130.769C59.079 131.215 58.1033 131.258 57.0676 130.901C56.0221 130.539 55.2761 129.95 54.8296 129.134C54.383 128.317 54.3728 127.292 54.7988 126.059C55.2247 124.826 55.8956 123.987 56.8113 123.542C57.7171 123.093 58.6928 123.049 59.7384 123.41ZM57.0789 130.772C57.8286 131.031 58.5658 130.91 59.2907 130.41C60.0122 129.919 60.5791 129.077 60.9915 127.884C61.4004 126.7 61.4769 125.744 61.2208 125.015C60.9647 124.286 60.4619 123.793 59.7123 123.534C58.9626 123.275 58.2271 123.39 57.5056 123.881C56.7807 124.381 56.2138 125.223 55.8048 126.407C55.3925 127.6 55.3143 128.561 55.5704 129.29C55.8265 130.019 56.3293 130.513 57.0789 130.772Z"/>
                <path d="M43.6188 124.36L43.4908 124.395C43.4648 124.356 43.4502 124.276 43.4471 124.155C43.435 124.028 43.4449 123.925 43.477 123.848C43.4999 123.765 43.5294 123.692 43.5654 123.628C43.7042 123.383 43.9001 123.164 44.153 122.971L49.8532 119.003L49.9894 119.08L48.6671 127.326L47.7135 126.786L48.8222 119.967L44.2892 123.048C44.0723 123.177 43.8893 123.374 43.7402 123.637C43.5911 123.9 43.5506 124.141 43.6188 124.36Z"/>
                <path d="M42.1161 118.368C42.9334 117.365 43.3592 116.487 43.3936 115.735C43.4199 114.975 43.1458 114.362 42.5714 113.894C41.7623 113.235 40.9433 112.769 40.1145 112.498L40.1473 112.383C40.9989 112.66 41.8333 113.131 42.6504 113.797C43.4595 114.456 43.9212 115.236 44.0355 116.136C44.1497 117.037 43.7949 117.993 42.9711 119.004C42.1472 120.016 41.2409 120.583 40.2521 120.706C39.2634 120.829 38.3563 120.555 37.5311 119.883C36.8676 119.342 36.4708 118.689 36.3407 117.924C36.2106 117.158 36.4619 116.387 37.0946 115.61C37.1276 115.57 37.1605 115.529 37.1935 115.489L41.5261 119.018C41.7283 118.82 41.925 118.603 42.1161 118.368ZM37.9396 116.258C37.315 117.042 36.9967 117.738 36.9848 118.347C36.9729 118.957 37.1975 119.449 37.6587 119.825C38.1199 120.201 38.6973 120.335 39.3909 120.227C40.0765 120.112 40.7578 119.738 41.4349 119.106L37.9396 116.258Z"/>
                <path d="M31.4382 100.682L34.8309 105.568L25.8307 111.818L25.0897 110.751L33.9613 104.59L31.3096 100.771L31.4382 100.682Z"/>
                <path d="M29.5862 96.6478C30.0355 97.6472 30.1159 98.5894 29.8272 99.4743C29.5386 100.359 28.7994 101.069 27.6097 101.604C26.4199 102.139 25.3512 102.23 24.4035 101.878C23.4515 101.517 22.7508 100.837 22.3015 99.8372C21.8479 98.8283 21.7654 97.8814 22.0541 96.9965C22.3427 96.1116 23.0819 95.4016 24.2716 94.8667C25.4614 94.3318 26.5322 94.2451 27.4842 94.6066C28.4319 94.9585 29.1326 95.6389 29.5862 96.6478ZM22.4029 99.7573C22.7281 100.481 23.3213 100.935 24.1825 101.12C25.0341 101.309 26.0358 101.145 27.1875 100.627C28.3296 100.113 29.0783 99.5135 29.4334 98.8275C29.7886 98.1415 29.8036 97.4368 29.4784 96.7135C29.1532 95.9901 28.5647 95.5338 27.7131 95.3447C26.8519 95.1598 25.8502 95.3241 24.7081 95.8376C23.5564 96.3554 22.803 96.9573 22.4478 97.6433C22.0926 98.3293 22.0777 99.034 22.4029 99.7573Z"/>
                <path d="M17.8458 85.5575L17.7324 85.4883C17.7429 85.4429 17.7914 85.3774 17.8781 85.2919C17.9622 85.1963 18.0437 85.1336 18.1223 85.1038C18.1986 85.0638 18.2721 85.0352 18.3431 85.018C18.617 84.9516 18.9106 84.9448 19.2241 84.9976L26.0176 86.4418L26.0545 86.5939L19.1351 91.2689L18.8767 90.204L24.6097 86.3485L19.261 85.1497C19.0185 85.0796 18.7502 85.0803 18.4561 85.1517C18.162 85.223 17.9586 85.3583 17.8458 85.5575Z"/>
                <path d="M21.1749 80.3988C22.4658 80.309 23.3976 80.0193 23.9702 79.5297C24.5422 79.0296 24.8025 78.41 24.7511 77.6709C24.6786 76.6298 24.458 75.7142 24.0893 74.9239L24.1953 74.8694C24.576 75.6798 24.8028 76.6107 24.876 77.6622C24.9484 78.7032 24.6951 79.5734 24.1161 80.2727C23.5371 80.972 22.5969 81.3669 21.2956 81.4574C19.9943 81.5479 18.9606 81.2746 18.1945 80.6375C17.4284 80.0003 17.0084 79.1509 16.9346 78.089C16.8752 77.2353 17.0803 76.4993 17.5498 75.8808C18.0194 75.2624 18.7539 74.9184 19.7533 74.8488C19.8053 74.8452 19.8574 74.8416 19.9094 74.838L20.2972 80.4128C20.5804 80.4244 20.873 80.4198 21.1749 80.3988ZM19.8584 75.9085C18.8597 75.9885 18.1339 76.2325 17.6811 76.6405C17.2283 77.0486 17.0226 77.5494 17.0638 78.1428C17.1051 78.7362 17.4023 79.249 17.9554 79.6812C18.5078 80.1031 19.2464 80.3446 20.1712 80.4058L19.8584 75.9085Z"/>
                <path d="M26.7993 60.2591L25.5291 66.0702L14.8245 63.7303L15.102 62.4611L25.6537 64.7675L26.6464 60.2257L26.7993 60.2591Z"/>
                <path d="M28.4832 56.1393C28.0563 57.1485 27.4204 57.8483 26.5754 58.2387C25.7305 58.6291 24.7073 58.5703 23.5059 58.0621C22.3045 57.554 21.5104 56.8329 21.1237 55.8988C20.741 54.9551 20.7631 53.9787 21.1899 52.9695C21.6208 51.9507 22.2588 51.2461 23.1038 50.8557C23.9487 50.4653 24.9719 50.5241 26.1733 51.0323C27.3747 51.5404 28.1668 52.2664 28.5494 53.21C28.9362 54.1441 28.9141 55.1205 28.4832 56.1393ZM21.3175 52.9895C21.0085 53.7199 21.0792 54.4637 21.5295 55.2206C21.9702 55.9736 22.772 56.596 23.935 57.0879C25.0883 57.5757 26.0372 57.7164 26.7816 57.5101C27.5261 57.3038 28.0528 56.8354 28.3617 56.1049C28.6707 55.3745 28.6048 54.6328 28.1641 53.8799C27.7138 53.1229 26.912 52.5005 25.7587 52.0126C24.5957 51.5207 23.642 51.378 22.8976 51.5843C22.1531 51.7906 21.6264 52.259 21.3175 52.9895Z"/>
                <path d="M28.4888 40.147L28.4619 40.0169C28.5023 39.9935 28.5833 39.9843 28.7049 39.9893C28.8322 39.9855 28.9336 40.0022 29.0091 40.0393C29.0903 40.0677 29.1615 40.102 29.2225 40.1421C29.458 40.2968 29.6635 40.5068 29.8389 40.7719L33.4213 46.722L33.3353 46.8528L25.1955 44.9881L25.7972 44.0724L32.5283 45.6295L29.7529 40.9027C29.6385 40.6777 29.4549 40.4821 29.202 40.3159C28.9491 40.1497 28.7113 40.0934 28.4888 40.147Z"/>
                <path d="M34.7168 38.8099C35.6612 39.6945 36.5077 40.1799 37.2562 40.2661C38.0118 40.3448 38.6429 40.1137 39.1494 39.573C39.8628 38.8113 40.3834 38.0265 40.7114 37.2184L40.8235 37.259C40.4889 38.0895 39.9613 38.8893 39.2408 39.6586C38.5274 40.4202 37.7175 40.8269 36.8111 40.8787C35.9047 40.9305 34.9754 40.5106 34.0234 39.6188C33.0713 38.7271 32.568 37.7838 32.5133 36.7889C32.4586 35.7939 32.7951 34.9081 33.5227 34.1312C34.1077 33.5066 34.7865 33.1559 35.5592 33.0789C36.3319 33.002 37.0839 33.3059 37.815 33.9908C37.8531 34.0264 37.8912 34.0621 37.9293 34.0978L34.1091 38.1763C34.2933 38.3918 34.4959 38.603 34.7168 38.8099ZM37.1102 34.789C36.3719 34.1118 35.6992 33.7462 35.092 33.6922C34.4848 33.6383 33.9779 33.8284 33.5713 34.2625C33.1647 34.6966 32.9913 35.2634 33.0511 35.9628C33.118 36.6546 33.4438 37.3601 34.0284 38.0793L37.1102 34.789Z"/>
                <path d="M53.1227 29.3141L48.0125 32.3584L42.4047 22.9448L43.5209 22.2799L49.0486 31.559L53.0426 29.1797L53.1227 29.3141Z"/>
                <path d="M57.089 27.7987C56.0617 28.1798 55.1163 28.1966 54.2527 27.8491C53.3892 27.5016 52.7306 26.7163 52.277 25.4933C51.8233 24.2703 51.8039 23.1978 52.2188 22.2759C52.6435 21.3504 53.3695 20.6971 54.3968 20.316C55.4339 19.9313 56.3842 19.9127 57.2477 20.2602C58.1112 20.6077 58.7698 21.393 59.2235 22.616C59.6772 23.839 59.6917 24.9133 59.267 25.8388C58.8521 26.7607 58.1261 27.414 57.089 27.7987ZM54.4697 20.4225C53.7261 20.6984 53.2331 21.2597 52.9907 22.1065C52.7447 22.9435 52.8412 23.9539 53.2804 25.1378C53.7159 26.3119 54.264 27.0992 54.9245 27.4997C55.5851 27.9002 56.2872 27.9626 57.0308 27.6867C57.7744 27.4109 58.2692 26.8545 58.5152 26.0175C58.7576 25.1707 58.6611 24.1603 58.2255 22.9862C57.7864 21.8023 57.2365 21.0101 56.5759 20.6096C55.9154 20.209 55.2133 20.1467 54.4697 20.4225Z"/>
                <path d="M69.1183 16.7775L69.1951 16.6691C69.2397 16.6826 69.3017 16.7355 69.381 16.8279C69.4706 16.9184 69.5276 17.0039 69.5519 17.0845C69.5866 17.1632 69.6101 17.2386 69.6224 17.3106C69.6699 17.5884 69.6565 17.8818 69.5823 18.1909L67.6751 24.8692L67.5208 24.8956L63.3319 17.6716L64.4119 17.4869L67.8648 23.4711L69.428 18.2173C69.5145 17.9802 69.5323 17.7125 69.4813 17.4142C69.4303 17.1159 69.3093 16.9036 69.1183 16.7775Z"/>
                <path d="M74.0363 20.4358C74.0363 21.7298 74.2606 22.6794 74.7094 23.2847C75.1685 23.89 75.7686 24.1926 76.5095 24.1926C77.5531 24.1926 78.4818 24.0361 79.2958 23.723L79.3428 23.8326C78.5079 24.1561 77.5635 24.3178 76.5095 24.3178C75.466 24.3178 74.6155 24.0048 73.958 23.3786C73.3006 22.7525 72.9718 21.7872 72.9718 20.4828C72.9718 19.1783 73.3162 18.1661 74.005 17.446C74.6937 16.726 75.5703 16.3659 76.6347 16.3659C77.4904 16.3659 78.2105 16.6216 78.7949 17.1329C79.3793 17.6443 79.6715 18.4009 79.6715 19.4027C79.6715 19.4549 79.6715 19.507 79.6715 19.5592H74.0832C74.0519 19.841 74.0363 20.1332 74.0363 20.4358ZM78.6071 19.434C78.5966 18.4322 78.4036 17.6912 78.0279 17.2112C77.6522 16.7312 77.1669 16.4912 76.5721 16.4912C75.9773 16.4912 75.4451 16.752 74.9755 17.2738C74.5163 17.7956 74.2241 18.5157 74.0989 19.434H78.6071Z"/>
                <path d="M151.666 76.4534C151.666 118.177 117.842 152 76.1189 152C34.3956 152 0.572266 118.177 0.572266 76.4534C0.572266 34.7301 34.3956 0.906738 76.1189 0.906738C117.842 0.906738 151.666 34.7301 151.666 76.4534Z"/>
            </mask>
            <path d="M126.011 83.3701L126.492 77.4413L137.413 78.3279L137.308 79.6228L126.543 78.7489L126.167 83.3827L126.011 83.3701Z" fill="black"/>
            <path d="M124.889 87.6902C125.176 86.6326 125.711 85.8533 126.496 85.3523C127.28 84.8513 128.302 84.7714 129.561 85.1126C130.82 85.4538 131.704 86.061 132.214 86.9343C132.72 87.8176 132.83 88.7881 132.544 89.8457C132.255 90.9133 131.718 91.6977 130.933 92.1987C130.149 92.6997 129.127 92.7796 127.868 92.4384C126.609 92.0972 125.726 91.4849 125.219 90.6016C124.71 89.7283 124.6 88.7579 124.889 87.6902ZM132.415 89.8431C132.622 89.0776 132.452 88.3503 131.903 87.661C131.365 86.9746 130.486 86.4662 129.268 86.1359C128.059 85.8084 127.1 85.7971 126.39 86.1021C125.68 86.4071 125.222 86.9424 125.014 87.7079C124.807 88.4734 124.972 89.1994 125.511 89.8858C126.059 90.5751 126.938 91.0834 128.146 91.411C129.365 91.7413 130.329 91.7539 131.039 91.4489C131.749 91.1439 132.207 90.6086 132.415 89.8431Z" fill="black"/>
            <path d="M126.923 103.736L126.966 103.861C126.929 103.89 126.85 103.91 126.729 103.921C126.604 103.942 126.501 103.938 126.421 103.912C126.337 103.894 126.262 103.87 126.196 103.838C125.942 103.716 125.711 103.535 125.502 103.295L121.163 97.872L121.231 97.731L129.546 98.5016L129.071 99.489L122.193 98.8366L125.57 103.154C125.713 103.362 125.921 103.532 126.193 103.663C126.466 103.794 126.709 103.819 126.923 103.736Z" fill="black"/>
            <path d="M121.028 105.678C119.973 104.928 119.069 104.561 118.316 104.576C117.556 104.6 116.962 104.913 116.533 105.517C115.928 106.368 115.518 107.216 115.301 108.06L115.185 108.035C115.405 107.167 115.82 106.304 116.431 105.445C117.035 104.594 117.783 104.082 118.674 103.909C119.566 103.736 120.543 104.027 121.606 104.783C122.669 105.539 123.295 106.406 123.483 107.385C123.671 108.363 123.456 109.286 122.84 110.154C122.344 110.851 121.718 111.29 120.963 111.47C120.208 111.65 119.422 111.45 118.605 110.87C118.563 110.839 118.52 110.809 118.477 110.779L121.715 106.224C121.503 106.035 121.274 105.853 121.028 105.678ZM119.196 109.984C120.019 110.556 120.735 110.828 121.343 110.8C121.952 110.771 122.429 110.515 122.774 110.03C123.118 109.545 123.214 108.96 123.061 108.275C122.902 107.599 122.484 106.943 121.808 106.309L119.196 109.984Z" fill="black"/>
            <path d="M104.041 117.546L108.693 113.84L115.521 122.41L114.504 123.22L107.775 114.772L104.138 117.668L104.041 117.546Z" fill="black"/>
            <path d="M100.122 119.668C101.089 119.153 102.024 119.01 102.926 119.239C103.828 119.468 104.586 120.158 105.199 121.309C105.812 122.461 105.975 123.521 105.687 124.49C105.39 125.464 104.758 126.208 103.79 126.723C102.814 127.243 101.875 127.389 100.973 127.16C100.07 126.931 99.3127 126.241 98.6996 125.089C98.0865 123.938 97.9285 122.875 98.2256 121.901C98.5135 120.932 99.1456 120.188 100.122 119.668ZM103.704 126.628C104.404 126.255 104.818 125.633 104.945 124.761C105.076 123.899 104.846 122.91 104.252 121.796C103.664 120.69 103.015 119.983 102.307 119.675C101.599 119.366 100.895 119.398 100.195 119.771C99.4946 120.144 99.0786 120.761 98.9467 121.624C98.8197 122.495 99.0505 123.484 99.6391 124.589C100.233 125.704 100.883 126.415 101.592 126.724C102.3 127.032 103.004 127 103.704 126.628Z" fill="black"/>
            <path d="M89.8186 132.126L89.7574 132.243C89.7113 132.236 89.6426 132.192 89.5515 132.112C89.4504 132.034 89.3822 131.957 89.3471 131.881C89.3021 131.807 89.2685 131.736 89.2465 131.666C89.1615 131.398 89.1347 131.105 89.166 130.789L90.1438 123.913L90.293 123.865L95.4288 130.45L94.3841 130.78L90.1467 125.324L89.3152 130.742C89.2619 130.988 89.2809 131.256 89.3721 131.544C89.4634 131.833 89.6122 132.027 89.8186 132.126Z" fill="black"/>
            <path d="M84.3853 129.142C84.2113 127.86 83.8613 126.949 83.3353 126.41C82.7989 125.872 82.1637 125.652 81.4295 125.752C80.3954 125.892 79.4961 126.172 78.7316 126.592L78.6703 126.49C79.4541 126.057 80.3682 125.77 81.4126 125.628C82.4467 125.488 83.3316 125.684 84.0672 126.216C84.8029 126.748 85.2584 127.66 85.4337 128.953C85.6091 130.245 85.4039 131.295 84.8182 132.101C84.2325 132.907 83.4123 133.381 82.3575 133.525C81.5096 133.64 80.7617 133.483 80.1139 133.055C79.466 132.627 79.0748 131.916 78.9401 130.924C78.9331 130.872 78.9261 130.82 78.9191 130.768L84.4566 130.017C84.4497 129.734 84.426 129.442 84.3853 129.142ZM79.9907 130.749C80.1357 131.741 80.4266 132.449 80.8634 132.874C81.3002 133.299 81.8133 133.472 82.4027 133.392C82.9922 133.312 83.4845 132.982 83.8797 132.402C84.2645 131.823 84.4573 131.07 84.4579 130.143L79.9907 130.749Z" fill="black"/>
            <path d="M63.9276 124.833L69.8123 125.701L68.2144 136.541L66.9291 136.351L68.5041 125.666L63.9048 124.988L63.9276 124.833Z" fill="black"/>
            <path d="M59.7384 123.41C60.774 123.768 61.5151 124.355 61.9616 125.172C62.4082 125.989 62.4184 127.013 61.9925 128.246C61.5665 129.479 60.9006 130.32 59.9947 130.769C59.079 131.215 58.1033 131.258 57.0676 130.901C56.0221 130.539 55.2761 129.95 54.8296 129.134C54.383 128.317 54.3728 127.292 54.7988 126.059C55.2247 124.826 55.8956 123.987 56.8113 123.542C57.7171 123.093 58.6928 123.049 59.7384 123.41ZM57.0789 130.772C57.8286 131.031 58.5658 130.91 59.2907 130.41C60.0122 129.919 60.5791 129.077 60.9915 127.884C61.4004 126.7 61.4769 125.744 61.2208 125.015C60.9647 124.286 60.4619 123.793 59.7123 123.534C58.9626 123.275 58.2271 123.39 57.5056 123.881C56.7807 124.381 56.2138 125.223 55.8048 126.407C55.3925 127.6 55.3143 128.561 55.5704 129.29C55.8265 130.019 56.3293 130.513 57.0789 130.772Z" fill="black"/>
            <path d="M43.6188 124.36L43.4908 124.395C43.4648 124.356 43.4502 124.276 43.4471 124.155C43.435 124.028 43.4449 123.925 43.477 123.848C43.4999 123.765 43.5294 123.692 43.5654 123.628C43.7042 123.383 43.9001 123.164 44.153 122.971L49.8532 119.003L49.9894 119.08L48.6671 127.326L47.7135 126.786L48.8222 119.967L44.2892 123.048C44.0723 123.177 43.8893 123.374 43.7402 123.637C43.5911 123.9 43.5506 124.141 43.6188 124.36Z" fill="black"/>
            <path d="M42.1161 118.368C42.9334 117.365 43.3592 116.487 43.3936 115.735C43.4199 114.975 43.1458 114.362 42.5714 113.894C41.7623 113.235 40.9433 112.769 40.1145 112.498L40.1473 112.383C40.9989 112.66 41.8333 113.131 42.6504 113.797C43.4595 114.456 43.9212 115.236 44.0355 116.136C44.1497 117.037 43.7949 117.993 42.9711 119.004C42.1472 120.016 41.2409 120.583 40.2521 120.706C39.2634 120.829 38.3563 120.555 37.5311 119.883C36.8676 119.342 36.4708 118.689 36.3407 117.924C36.2106 117.158 36.4619 116.387 37.0946 115.61C37.1276 115.57 37.1605 115.529 37.1935 115.489L41.5261 119.018C41.7283 118.82 41.925 118.603 42.1161 118.368ZM37.9396 116.258C37.315 117.042 36.9967 117.738 36.9848 118.347C36.9729 118.957 37.1975 119.449 37.6587 119.825C38.1199 120.201 38.6973 120.335 39.3909 120.227C40.0765 120.112 40.7578 119.738 41.4349 119.106L37.9396 116.258Z" fill="black"/>
            <path d="M31.4382 100.682L34.8309 105.568L25.8307 111.818L25.0897 110.751L33.9613 104.59L31.3096 100.771L31.4382 100.682Z" fill="black"/>
            <path d="M29.5862 96.6478C30.0355 97.6472 30.1159 98.5894 29.8272 99.4743C29.5386 100.359 28.7994 101.069 27.6097 101.604C26.4199 102.139 25.3512 102.23 24.4035 101.878C23.4515 101.517 22.7508 100.837 22.3015 99.8372C21.8479 98.8283 21.7654 97.8814 22.0541 96.9965C22.3427 96.1116 23.0819 95.4016 24.2716 94.8667C25.4614 94.3318 26.5322 94.2451 27.4842 94.6066C28.4319 94.9585 29.1326 95.6389 29.5862 96.6478ZM22.4029 99.7573C22.7281 100.481 23.3213 100.935 24.1825 101.12C25.0341 101.309 26.0358 101.145 27.1875 100.627C28.3296 100.113 29.0783 99.5135 29.4334 98.8275C29.7886 98.1415 29.8036 97.4368 29.4784 96.7135C29.1532 95.9901 28.5647 95.5338 27.7131 95.3447C26.8519 95.1598 25.8502 95.3241 24.7081 95.8376C23.5564 96.3554 22.803 96.9573 22.4478 97.6433C22.0926 98.3293 22.0777 99.034 22.4029 99.7573Z" fill="black"/>
            <path d="M17.8458 85.5575L17.7324 85.4883C17.7429 85.4429 17.7914 85.3774 17.8781 85.2919C17.9622 85.1963 18.0437 85.1336 18.1223 85.1038C18.1986 85.0638 18.2721 85.0352 18.3431 85.018C18.617 84.9516 18.9106 84.9448 19.2241 84.9976L26.0176 86.4418L26.0545 86.5939L19.1351 91.2689L18.8767 90.204L24.6097 86.3485L19.261 85.1497C19.0185 85.0796 18.7502 85.0803 18.4561 85.1517C18.162 85.223 17.9586 85.3583 17.8458 85.5575Z" fill="black"/>
            <path d="M21.1749 80.3988C22.4658 80.309 23.3976 80.0193 23.9702 79.5297C24.5422 79.0296 24.8025 78.41 24.7511 77.6709C24.6786 76.6298 24.458 75.7142 24.0893 74.9239L24.1953 74.8694C24.576 75.6798 24.8028 76.6107 24.876 77.6622C24.9484 78.7032 24.6951 79.5734 24.1161 80.2727C23.5371 80.972 22.5969 81.3669 21.2956 81.4574C19.9943 81.5479 18.9606 81.2746 18.1945 80.6375C17.4284 80.0003 17.0084 79.1509 16.9346 78.089C16.8752 77.2353 17.0803 76.4993 17.5498 75.8808C18.0194 75.2624 18.7539 74.9184 19.7533 74.8488C19.8053 74.8452 19.8574 74.8416 19.9094 74.838L20.2972 80.4128C20.5804 80.4244 20.873 80.4198 21.1749 80.3988ZM19.8584 75.9085C18.8597 75.9885 18.1339 76.2325 17.6811 76.6405C17.2283 77.0486 17.0226 77.5494 17.0638 78.1428C17.1051 78.7362 17.4023 79.249 17.9554 79.6812C18.5078 80.1031 19.2464 80.3446 20.1712 80.4058L19.8584 75.9085Z" fill="black"/>
            <path d="M26.7993 60.2591L25.5291 66.0702L14.8245 63.7303L15.102 62.4611L25.6537 64.7675L26.6464 60.2257L26.7993 60.2591Z" fill="black"/>
            <path d="M28.4832 56.1393C28.0563 57.1485 27.4204 57.8483 26.5754 58.2387C25.7305 58.6291 24.7073 58.5703 23.5059 58.0621C22.3045 57.554 21.5104 56.8329 21.1237 55.8988C20.741 54.9551 20.7631 53.9787 21.1899 52.9695C21.6208 51.9507 22.2588 51.2461 23.1038 50.8557C23.9487 50.4653 24.9719 50.5241 26.1733 51.0323C27.3747 51.5404 28.1668 52.2664 28.5494 53.21C28.9362 54.1441 28.9141 55.1205 28.4832 56.1393ZM21.3175 52.9895C21.0085 53.7199 21.0792 54.4637 21.5295 55.2206C21.9702 55.9736 22.772 56.596 23.935 57.0879C25.0883 57.5757 26.0372 57.7164 26.7816 57.5101C27.5261 57.3038 28.0528 56.8354 28.3617 56.1049C28.6707 55.3745 28.6048 54.6328 28.1641 53.8799C27.7138 53.1229 26.912 52.5005 25.7587 52.0126C24.5957 51.5207 23.642 51.378 22.8976 51.5843C22.1531 51.7906 21.6264 52.259 21.3175 52.9895Z" fill="black"/>
            <path d="M28.4888 40.147L28.4619 40.0169C28.5023 39.9935 28.5833 39.9843 28.7049 39.9893C28.8322 39.9855 28.9336 40.0022 29.0091 40.0393C29.0903 40.0677 29.1615 40.102 29.2225 40.1421C29.458 40.2968 29.6635 40.5068 29.8389 40.7719L33.4213 46.722L33.3353 46.8528L25.1955 44.9881L25.7972 44.0724L32.5283 45.6295L29.7529 40.9027C29.6385 40.6777 29.4549 40.4821 29.202 40.3159C28.9491 40.1497 28.7113 40.0934 28.4888 40.147Z" fill="black"/>
            <path d="M34.7168 38.8099C35.6612 39.6945 36.5077 40.1799 37.2562 40.2661C38.0118 40.3448 38.6429 40.1137 39.1494 39.573C39.8628 38.8113 40.3834 38.0265 40.7114 37.2184L40.8235 37.259C40.4889 38.0895 39.9613 38.8893 39.2408 39.6586C38.5274 40.4202 37.7175 40.8269 36.8111 40.8787C35.9047 40.9305 34.9754 40.5106 34.0234 39.6188C33.0713 38.7271 32.568 37.7838 32.5133 36.7889C32.4586 35.7939 32.7951 34.9081 33.5227 34.1312C34.1077 33.5066 34.7865 33.1559 35.5592 33.0789C36.3319 33.002 37.0839 33.3059 37.815 33.9908C37.8531 34.0264 37.8912 34.0621 37.9293 34.0978L34.1091 38.1763C34.2933 38.3918 34.4959 38.603 34.7168 38.8099ZM37.1102 34.789C36.3719 34.1118 35.6992 33.7462 35.092 33.6922C34.4848 33.6383 33.9779 33.8284 33.5713 34.2625C33.1647 34.6966 32.9913 35.2634 33.0511 35.9628C33.118 36.6546 33.4438 37.3601 34.0284 38.0793L37.1102 34.789Z" fill="black"/>
            <path d="M53.1227 29.3141L48.0125 32.3584L42.4047 22.9448L43.5209 22.2799L49.0486 31.559L53.0426 29.1797L53.1227 29.3141Z" fill="black"/>
            <path d="M57.089 27.7987C56.0617 28.1798 55.1163 28.1966 54.2527 27.8491C53.3892 27.5016 52.7306 26.7163 52.277 25.4933C51.8233 24.2703 51.8039 23.1978 52.2188 22.2759C52.6435 21.3504 53.3695 20.6971 54.3968 20.316C55.4339 19.9313 56.3842 19.9127 57.2477 20.2602C58.1112 20.6077 58.7698 21.393 59.2235 22.616C59.6772 23.839 59.6917 24.9133 59.267 25.8388C58.8521 26.7607 58.1261 27.414 57.089 27.7987ZM54.4697 20.4225C53.7261 20.6984 53.2331 21.2597 52.9907 22.1065C52.7447 22.9435 52.8412 23.9539 53.2804 25.1378C53.7159 26.3119 54.264 27.0992 54.9245 27.4997C55.5851 27.9002 56.2872 27.9626 57.0308 27.6867C57.7744 27.4109 58.2692 26.8545 58.5152 26.0175C58.7576 25.1707 58.6611 24.1603 58.2255 22.9862C57.7864 21.8023 57.2365 21.0101 56.5759 20.6096C55.9154 20.209 55.2133 20.1467 54.4697 20.4225Z" fill="black"/>
            <path d="M69.1183 16.7775L69.1951 16.6691C69.2397 16.6826 69.3017 16.7355 69.381 16.8279C69.4706 16.9184 69.5276 17.0039 69.5519 17.0845C69.5866 17.1632 69.6101 17.2386 69.6224 17.3106C69.6699 17.5884 69.6565 17.8818 69.5823 18.1909L67.6751 24.8692L67.5208 24.8956L63.3319 17.6716L64.4119 17.4869L67.8648 23.4711L69.428 18.2173C69.5145 17.9802 69.5323 17.7125 69.4813 17.4142C69.4303 17.1159 69.3093 16.9036 69.1183 16.7775Z" fill="black"/>
            <path d="M74.0363 20.4358C74.0363 21.7298 74.2606 22.6794 74.7094 23.2847C75.1685 23.89 75.7686 24.1926 76.5095 24.1926C77.5531 24.1926 78.4818 24.0361 79.2958 23.723L79.3428 23.8326C78.5079 24.1561 77.5635 24.3178 76.5095 24.3178C75.466 24.3178 74.6155 24.0048 73.958 23.3786C73.3006 22.7525 72.9718 21.7872 72.9718 20.4828C72.9718 19.1783 73.3162 18.1661 74.005 17.446C74.6937 16.726 75.5703 16.3659 76.6347 16.3659C77.4904 16.3659 78.2105 16.6216 78.7949 17.1329C79.3793 17.6443 79.6715 18.4009 79.6715 19.4027C79.6715 19.4549 79.6715 19.507 79.6715 19.5592H74.0832C74.0519 19.841 74.0363 20.1332 74.0363 20.4358ZM78.6071 19.434C78.5966 18.4322 78.4036 17.6912 78.0279 17.2112C77.6522 16.7312 77.1669 16.4912 76.5721 16.4912C75.9773 16.4912 75.4451 16.752 74.9755 17.2738C74.5163 17.7956 74.2241 18.5157 74.0989 19.434H78.6071Z" fill="black"/>
            <path d="M126.011 83.3701L126.492 77.4413L137.413 78.3279L137.308 79.6228L126.543 78.7489L126.167 83.3827L126.011 83.3701Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M124.889 87.6902C125.176 86.6326 125.711 85.8533 126.496 85.3523C127.28 84.8513 128.302 84.7714 129.561 85.1126C130.82 85.4538 131.704 86.061 132.214 86.9343C132.72 87.8176 132.83 88.7881 132.544 89.8457C132.255 90.9133 131.718 91.6977 130.933 92.1987C130.149 92.6997 129.127 92.7796 127.868 92.4384C126.609 92.0972 125.726 91.4849 125.219 90.6016C124.71 89.7283 124.6 88.7579 124.889 87.6902ZM132.415 89.8431C132.622 89.0776 132.452 88.3503 131.903 87.661C131.365 86.9746 130.486 86.4662 129.268 86.1359C128.059 85.8084 127.1 85.7971 126.39 86.1021C125.68 86.4071 125.222 86.9424 125.014 87.7079C124.807 88.4734 124.972 89.1994 125.511 89.8858C126.059 90.5751 126.938 91.0834 128.146 91.411C129.365 91.7413 130.329 91.7539 131.039 91.4489C131.749 91.1439 132.207 90.6086 132.415 89.8431Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M126.923 103.736L126.966 103.861C126.929 103.89 126.85 103.91 126.729 103.921C126.604 103.942 126.501 103.938 126.421 103.912C126.337 103.894 126.262 103.87 126.196 103.838C125.942 103.716 125.711 103.535 125.502 103.295L121.163 97.872L121.231 97.731L129.546 98.5016L129.071 99.489L122.193 98.8366L125.57 103.154C125.713 103.362 125.921 103.532 126.193 103.663C126.466 103.794 126.709 103.819 126.923 103.736Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M121.028 105.678C119.973 104.928 119.069 104.561 118.316 104.576C117.556 104.6 116.962 104.913 116.533 105.517C115.928 106.368 115.518 107.216 115.301 108.06L115.185 108.035C115.405 107.167 115.82 106.304 116.431 105.445C117.035 104.594 117.783 104.082 118.674 103.909C119.566 103.736 120.543 104.027 121.606 104.783C122.669 105.539 123.295 106.406 123.483 107.385C123.671 108.363 123.456 109.286 122.84 110.154C122.344 110.851 121.718 111.29 120.963 111.47C120.208 111.65 119.422 111.45 118.605 110.87C118.563 110.839 118.52 110.809 118.477 110.779L121.715 106.224C121.503 106.035 121.274 105.853 121.028 105.678ZM119.196 109.984C120.019 110.556 120.735 110.828 121.343 110.8C121.952 110.771 122.429 110.515 122.774 110.03C123.118 109.545 123.214 108.96 123.061 108.275C122.902 107.599 122.484 106.943 121.808 106.309L119.196 109.984Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M104.041 117.546L108.693 113.84L115.521 122.41L114.504 123.22L107.775 114.772L104.138 117.668L104.041 117.546Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M100.122 119.668C101.089 119.153 102.024 119.01 102.926 119.239C103.828 119.468 104.586 120.158 105.199 121.309C105.812 122.461 105.975 123.521 105.687 124.49C105.39 125.464 104.758 126.208 103.79 126.723C102.814 127.243 101.875 127.389 100.973 127.16C100.07 126.931 99.3127 126.241 98.6996 125.089C98.0865 123.938 97.9285 122.875 98.2256 121.901C98.5135 120.932 99.1456 120.188 100.122 119.668ZM103.704 126.628C104.404 126.255 104.818 125.633 104.945 124.761C105.076 123.899 104.846 122.91 104.252 121.796C103.664 120.69 103.015 119.983 102.307 119.675C101.599 119.366 100.895 119.398 100.195 119.771C99.4946 120.144 99.0786 120.761 98.9467 121.624C98.8197 122.495 99.0505 123.484 99.6391 124.589C100.233 125.704 100.883 126.415 101.592 126.724C102.3 127.032 103.004 127 103.704 126.628Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M89.8186 132.126L89.7574 132.243C89.7113 132.236 89.6426 132.192 89.5515 132.112C89.4504 132.034 89.3822 131.957 89.3471 131.881C89.3021 131.807 89.2685 131.736 89.2465 131.666C89.1615 131.398 89.1347 131.105 89.166 130.789L90.1438 123.913L90.293 123.865L95.4288 130.45L94.3841 130.78L90.1467 125.324L89.3152 130.742C89.2619 130.988 89.2809 131.256 89.3721 131.544C89.4634 131.833 89.6122 132.027 89.8186 132.126Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M84.3853 129.142C84.2113 127.86 83.8613 126.949 83.3353 126.41C82.7989 125.872 82.1637 125.652 81.4295 125.752C80.3954 125.892 79.4961 126.172 78.7316 126.592L78.6703 126.49C79.4541 126.057 80.3682 125.77 81.4126 125.628C82.4467 125.488 83.3316 125.684 84.0672 126.216C84.8029 126.748 85.2584 127.66 85.4337 128.953C85.6091 130.245 85.4039 131.295 84.8182 132.101C84.2325 132.907 83.4123 133.381 82.3575 133.525C81.5096 133.64 80.7617 133.483 80.1139 133.055C79.466 132.627 79.0748 131.916 78.9401 130.924C78.9331 130.872 78.9261 130.82 78.9191 130.768L84.4566 130.017C84.4497 129.734 84.426 129.442 84.3853 129.142ZM79.9907 130.749C80.1357 131.741 80.4266 132.449 80.8634 132.874C81.3002 133.299 81.8133 133.472 82.4027 133.392C82.9922 133.312 83.4845 132.982 83.8797 132.402C84.2645 131.823 84.4573 131.07 84.4579 130.143L79.9907 130.749Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M63.9276 124.833L69.8123 125.701L68.2144 136.541L66.9291 136.351L68.5041 125.666L63.9048 124.988L63.9276 124.833Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M59.7384 123.41C60.774 123.768 61.5151 124.355 61.9616 125.172C62.4082 125.989 62.4184 127.013 61.9925 128.246C61.5665 129.479 60.9006 130.32 59.9947 130.769C59.079 131.215 58.1033 131.258 57.0676 130.901C56.0221 130.539 55.2761 129.95 54.8296 129.134C54.383 128.317 54.3728 127.292 54.7988 126.059C55.2247 124.826 55.8956 123.987 56.8113 123.542C57.7171 123.093 58.6928 123.049 59.7384 123.41ZM57.0789 130.772C57.8286 131.031 58.5658 130.91 59.2907 130.41C60.0122 129.919 60.5791 129.077 60.9915 127.884C61.4004 126.7 61.4769 125.744 61.2208 125.015C60.9647 124.286 60.4619 123.793 59.7123 123.534C58.9626 123.275 58.2271 123.39 57.5056 123.881C56.7807 124.381 56.2138 125.223 55.8048 126.407C55.3925 127.6 55.3143 128.561 55.5704 129.29C55.8265 130.019 56.3293 130.513 57.0789 130.772Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M43.6188 124.36L43.4908 124.395C43.4648 124.356 43.4502 124.276 43.4471 124.155C43.435 124.028 43.4449 123.925 43.477 123.848C43.4999 123.765 43.5294 123.692 43.5654 123.628C43.7042 123.383 43.9001 123.164 44.153 122.971L49.8532 119.003L49.9894 119.08L48.6671 127.326L47.7135 126.786L48.8222 119.967L44.2892 123.048C44.0723 123.177 43.8893 123.374 43.7402 123.637C43.5911 123.9 43.5506 124.141 43.6188 124.36Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M42.1161 118.368C42.9334 117.365 43.3592 116.487 43.3936 115.735C43.4199 114.975 43.1458 114.362 42.5714 113.894C41.7623 113.235 40.9433 112.769 40.1145 112.498L40.1473 112.383C40.9989 112.66 41.8333 113.131 42.6504 113.797C43.4595 114.456 43.9212 115.236 44.0355 116.136C44.1497 117.037 43.7949 117.993 42.9711 119.004C42.1472 120.016 41.2409 120.583 40.2521 120.706C39.2634 120.829 38.3563 120.555 37.5311 119.883C36.8676 119.342 36.4708 118.689 36.3407 117.924C36.2106 117.158 36.4619 116.387 37.0946 115.61C37.1276 115.57 37.1605 115.529 37.1935 115.489L41.5261 119.018C41.7283 118.82 41.925 118.603 42.1161 118.368ZM37.9396 116.258C37.315 117.042 36.9967 117.738 36.9848 118.347C36.9729 118.957 37.1975 119.449 37.6587 119.825C38.1199 120.201 38.6973 120.335 39.3909 120.227C40.0765 120.112 40.7578 119.738 41.4349 119.106L37.9396 116.258Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M31.4382 100.682L34.8309 105.568L25.8307 111.818L25.0897 110.751L33.9613 104.59L31.3096 100.771L31.4382 100.682Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M29.5862 96.6478C30.0355 97.6472 30.1159 98.5894 29.8272 99.4743C29.5386 100.359 28.7994 101.069 27.6097 101.604C26.4199 102.139 25.3512 102.23 24.4035 101.878C23.4515 101.517 22.7508 100.837 22.3015 99.8372C21.8479 98.8283 21.7654 97.8814 22.0541 96.9965C22.3427 96.1116 23.0819 95.4016 24.2716 94.8667C25.4614 94.3318 26.5322 94.2451 27.4842 94.6066C28.4319 94.9585 29.1326 95.6389 29.5862 96.6478ZM22.4029 99.7573C22.7281 100.481 23.3213 100.935 24.1825 101.12C25.0341 101.309 26.0358 101.145 27.1875 100.627C28.3296 100.113 29.0783 99.5135 29.4334 98.8275C29.7886 98.1415 29.8036 97.4368 29.4784 96.7135C29.1532 95.9901 28.5647 95.5338 27.7131 95.3447C26.8519 95.1598 25.8502 95.3241 24.7081 95.8376C23.5564 96.3554 22.803 96.9573 22.4478 97.6433C22.0926 98.3293 22.0777 99.034 22.4029 99.7573Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M17.8458 85.5575L17.7324 85.4883C17.7429 85.4429 17.7914 85.3774 17.8781 85.2919C17.9622 85.1963 18.0437 85.1336 18.1223 85.1038C18.1986 85.0638 18.2721 85.0352 18.3431 85.018C18.617 84.9516 18.9106 84.9448 19.2241 84.9976L26.0176 86.4418L26.0545 86.5939L19.1351 91.2689L18.8767 90.204L24.6097 86.3485L19.261 85.1497C19.0185 85.0796 18.7502 85.0803 18.4561 85.1517C18.162 85.223 17.9586 85.3583 17.8458 85.5575Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M21.1749 80.3988C22.4658 80.309 23.3976 80.0193 23.9702 79.5297C24.5422 79.0296 24.8025 78.41 24.7511 77.6709C24.6786 76.6298 24.458 75.7142 24.0893 74.9239L24.1953 74.8694C24.576 75.6798 24.8028 76.6107 24.876 77.6622C24.9484 78.7032 24.6951 79.5734 24.1161 80.2727C23.5371 80.972 22.5969 81.3669 21.2956 81.4574C19.9943 81.5479 18.9606 81.2746 18.1945 80.6375C17.4284 80.0003 17.0084 79.1509 16.9346 78.089C16.8752 77.2353 17.0803 76.4993 17.5498 75.8808C18.0194 75.2624 18.7539 74.9184 19.7533 74.8488C19.8053 74.8452 19.8574 74.8416 19.9094 74.838L20.2972 80.4128C20.5804 80.4244 20.873 80.4198 21.1749 80.3988ZM19.8584 75.9085C18.8597 75.9885 18.1339 76.2325 17.6811 76.6405C17.2283 77.0486 17.0226 77.5494 17.0638 78.1428C17.1051 78.7362 17.4023 79.249 17.9554 79.6812C18.5078 80.1031 19.2464 80.3446 20.1712 80.4058L19.8584 75.9085Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M26.7993 60.2591L25.5291 66.0702L14.8245 63.7303L15.102 62.4611L25.6537 64.7675L26.6464 60.2257L26.7993 60.2591Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M28.4832 56.1393C28.0563 57.1485 27.4204 57.8483 26.5754 58.2387C25.7305 58.6291 24.7073 58.5703 23.5059 58.0621C22.3045 57.554 21.5104 56.8329 21.1237 55.8988C20.741 54.9551 20.7631 53.9787 21.1899 52.9695C21.6208 51.9507 22.2588 51.2461 23.1038 50.8557C23.9487 50.4653 24.9719 50.5241 26.1733 51.0323C27.3747 51.5404 28.1668 52.2664 28.5494 53.21C28.9362 54.1441 28.9141 55.1205 28.4832 56.1393ZM21.3175 52.9895C21.0085 53.7199 21.0792 54.4637 21.5295 55.2206C21.9702 55.9736 22.772 56.596 23.935 57.0879C25.0883 57.5757 26.0372 57.7164 26.7816 57.5101C27.5261 57.3038 28.0528 56.8354 28.3617 56.1049C28.6707 55.3745 28.6048 54.6328 28.1641 53.8799C27.7138 53.1229 26.912 52.5005 25.7587 52.0126C24.5957 51.5207 23.642 51.378 22.8976 51.5843C22.1531 51.7906 21.6264 52.259 21.3175 52.9895Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M28.4888 40.147L28.4619 40.0169C28.5023 39.9935 28.5833 39.9843 28.7049 39.9893C28.8322 39.9855 28.9336 40.0022 29.0091 40.0393C29.0903 40.0677 29.1615 40.102 29.2225 40.1421C29.458 40.2968 29.6635 40.5068 29.8389 40.7719L33.4213 46.722L33.3353 46.8528L25.1955 44.9881L25.7972 44.0724L32.5283 45.6295L29.7529 40.9027C29.6385 40.6777 29.4549 40.4821 29.202 40.3159C28.9491 40.1497 28.7113 40.0934 28.4888 40.147Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M34.7168 38.8099C35.6612 39.6945 36.5077 40.1799 37.2562 40.2661C38.0118 40.3448 38.6429 40.1137 39.1494 39.573C39.8628 38.8113 40.3834 38.0265 40.7114 37.2184L40.8235 37.259C40.4889 38.0895 39.9613 38.8893 39.2408 39.6586C38.5274 40.4202 37.7175 40.8269 36.8111 40.8787C35.9047 40.9305 34.9754 40.5106 34.0234 39.6188C33.0713 38.7271 32.568 37.7838 32.5133 36.7889C32.4586 35.7939 32.7951 34.9081 33.5227 34.1312C34.1077 33.5066 34.7865 33.1559 35.5592 33.0789C36.3319 33.002 37.0839 33.3059 37.815 33.9908C37.8531 34.0264 37.8912 34.0621 37.9293 34.0978L34.1091 38.1763C34.2933 38.3918 34.4959 38.603 34.7168 38.8099ZM37.1102 34.789C36.3719 34.1118 35.6992 33.7462 35.092 33.6922C34.4848 33.6383 33.9779 33.8284 33.5713 34.2625C33.1647 34.6966 32.9913 35.2634 33.0511 35.9628C33.118 36.6546 33.4438 37.3601 34.0284 38.0793L37.1102 34.789Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M53.1227 29.3141L48.0125 32.3584L42.4047 22.9448L43.5209 22.2799L49.0486 31.559L53.0426 29.1797L53.1227 29.3141Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M57.089 27.7987C56.0617 28.1798 55.1163 28.1966 54.2527 27.8491C53.3892 27.5016 52.7306 26.7163 52.277 25.4933C51.8233 24.2703 51.8039 23.1978 52.2188 22.2759C52.6435 21.3504 53.3695 20.6971 54.3968 20.316C55.4339 19.9313 56.3842 19.9127 57.2477 20.2602C58.1112 20.6077 58.7698 21.393 59.2235 22.616C59.6772 23.839 59.6917 24.9133 59.267 25.8388C58.8521 26.7607 58.1261 27.414 57.089 27.7987ZM54.4697 20.4225C53.7261 20.6984 53.2331 21.2597 52.9907 22.1065C52.7447 22.9435 52.8412 23.9539 53.2804 25.1378C53.7159 26.3119 54.264 27.0992 54.9245 27.4997C55.5851 27.9002 56.2872 27.9626 57.0308 27.6867C57.7744 27.4109 58.2692 26.8545 58.5152 26.0175C58.7576 25.1707 58.6611 24.1603 58.2255 22.9862C57.7864 21.8023 57.2365 21.0101 56.5759 20.6096C55.9154 20.209 55.2133 20.1467 54.4697 20.4225Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M69.1183 16.7775L69.1951 16.6691C69.2397 16.6826 69.3017 16.7355 69.381 16.8279C69.4706 16.9184 69.5276 17.0039 69.5519 17.0845C69.5866 17.1632 69.6101 17.2386 69.6224 17.3106C69.6699 17.5884 69.6565 17.8818 69.5823 18.1909L67.6751 24.8692L67.5208 24.8956L63.3319 17.6716L64.4119 17.4869L67.8648 23.4711L69.428 18.2173C69.5145 17.9802 69.5323 17.7125 69.4813 17.4142C69.4303 17.1159 69.3093 16.9036 69.1183 16.7775Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M74.0363 20.4358C74.0363 21.7298 74.2606 22.6794 74.7094 23.2847C75.1685 23.89 75.7686 24.1926 76.5095 24.1926C77.5531 24.1926 78.4818 24.0361 79.2958 23.723L79.3428 23.8326C78.5079 24.1561 77.5635 24.3178 76.5095 24.3178C75.466 24.3178 74.6155 24.0048 73.958 23.3786C73.3006 22.7525 72.9718 21.7872 72.9718 20.4828C72.9718 19.1783 73.3162 18.1661 74.005 17.446C74.6937 16.726 75.5703 16.3659 76.6347 16.3659C77.4904 16.3659 78.2105 16.6216 78.7949 17.1329C79.3793 17.6443 79.6715 18.4009 79.6715 19.4027C79.6715 19.4549 79.6715 19.507 79.6715 19.5592H74.0832C74.0519 19.841 74.0363 20.1332 74.0363 20.4358ZM78.6071 19.434C78.5966 18.4322 78.4036 17.6912 78.0279 17.2112C77.6522 16.7312 77.1669 16.4912 76.5721 16.4912C75.9773 16.4912 75.4451 16.752 74.9755 17.2738C74.5163 17.7956 74.2241 18.5157 74.0989 19.434H78.6071Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
            <path d="M151.666 76.4534C151.666 118.177 117.842 152 76.1189 152C34.3956 152 0.572266 118.177 0.572266 76.4534C0.572266 34.7301 34.3956 0.906738 76.1189 0.906738C117.842 0.906738 151.666 34.7301 151.666 76.4534Z" stroke="black" mask="url(#path-1-inside-1_144_1796)"/>
        </svg>
    )
}
